import { type User, onAuthStateChanged } from 'firebase/auth';
import { useCallback } from 'react';
import { useAuth, useUser as useFirebaseUser } from 'reactfire';
import useSWR from 'swr';
import { decodeJwt } from 'jose';

export const useUser = () => {
  const user = useFirebaseUser().data as User; // user should already be defined if we are here
  const auth = useAuth();

  const fetchToken = useCallback(
    () =>
      new Promise<string>((resolve) => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          unsubscribe();

          try {
            if (user) {
              const token = await user.getIdToken();
              const decodedToken = decodeJwt(token);
              const shouldRefresh =
                !decodedToken.exp ||
                decodedToken.exp * 1000 - 10 * 60 * 1000 < Date.now(); // refresh 10 minutes before expiry

              if (shouldRefresh) {
                const token = await user.getIdToken(true);
                resolve(token);
              } else {
                resolve(token);
              }
            } else {
              // user is already signed out, so user will have been redirected at this point already
              resolve('');
            }
          } catch {
            resolve('');
          }
        });
      }),
    [auth],
  );

  const { data } = useSWR(['refreshAuthToken', user?.uid], fetchToken, {
    refreshInterval: 55 * 60 * 1000, // update every 55 minutes
  });
  const token = data ?? '';

  return {
    user,
    token,
  };
};
