import { useDefaultApplication } from '@/common/hooks/sdkHooks';
import { signOut } from '@/common/utils';
import {
  AccessRequestSignInProvider,
  InfrastructureEnv,
} from '@spherelabs/common';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import { useFirebaseApp, useUser } from 'reactfire';

export const useRouteToRedirectSignedInUser = () => {
  const application = useDefaultApplication();
  const { data: user } = useUser();
  const app = useFirebaseApp();
  const searchParams = useSearchParams();

  const routeToRedirect = useMemo(() => {
    if (!user) {
      return undefined;
    }

    const signInMethodHasEmail = Boolean(user.email);
    if (signInMethodHasEmail && !user.emailVerified) {
      return '/verify-email';
    }

    if (!application.data) {
      return undefined;
    }

    const isDevelopment =
      process.env.NEXT_PUBLIC_INFRASTRUCTURE_ENV ===
      InfrastructureEnv.DEVELOPMENT;

    const shouldRedirectToEarlyAccess = !application.data.access.early;
    if (shouldRedirectToEarlyAccess && !isDevelopment) {
      const url = '/en/access-request';
      const params = new URLSearchParams(searchParams.toString());
      if (!signInMethodHasEmail) {
        void signOut(app);
        params.set('providerId', AccessRequestSignInProvider.WALLET);
      } else {
        params.set('providerId', AccessRequestSignInProvider.GOOGLE);
      }
      return `${url}${params.toString() ? `?${params.toString()}` : ''}`;
    }

    const shouldRedirectToSetup = !application.data.initialized;
    if (shouldRedirectToSetup) {
      return '/setup';
    }

    return null;
  }, [application, user]);

  return routeToRedirect;
};
