'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { useSigninCheck, useUser } from 'reactfire';

import { FullScreenSpinner } from '@/common/components/FullScreenSpinner';
import { useDefaultApplication } from '@/common/hooks/sdkHooks';
import { useCreateQueryString } from '@/common/hooks/useCreateQueryString';
import { FirebaseTokenLoader } from '@/features/login/components/FirebaseTokenLoader';
import { REDIRECT_QUERY_PARAM } from '@/features/login/constants';
import { useRouteToRedirectSignedInUser } from '@/features/login/hooks/useRouteToRedirectSignedInUser';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { status: signInCheckStatus, data: signInCheckResult } =
    useSigninCheck();
  const { data: userData } = useUser();
  if (
    signInCheckStatus !== 'success' ||
    (signInCheckResult.signedIn && !userData)
  ) {
    return <FullScreenSpinner />;
  }

  if (userData) {
    return (
      <FirebaseTokenLoader>
        <RedirectSignedInUser />
      </FirebaseTokenLoader>
    );
  }

  return <main>{children}</main>;
}

const RedirectSignedInUser = () => {
  const searchParams = useSearchParams();
  const redirectQueryParam = searchParams?.get(REDIRECT_QUERY_PARAM);
  const createQueryString = useCreateQueryString();
  const { replace } = useRouter();
  const routeToRedirect = useRouteToRedirectSignedInUser();

  useEffect(
    function redirect() {
      if (routeToRedirect === undefined) {
        return;
      }
      if (routeToRedirect === null) {
        replace(
          createQueryString(
            {
              [REDIRECT_QUERY_PARAM]: null,
            },
            redirectQueryParam ?? '/dashboard',
          ),
        );
      } else {
        replace(routeToRedirect);
      }
    },
    [replace, routeToRedirect, createQueryString, redirectQueryParam],
  );

  return <FullScreenSpinner />;
};
