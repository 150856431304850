import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

export const useCreateQueryString = () => {
  const currentPathname = usePathname();
  const searchParams = useSearchParams();

  const createQueryString = useCallback(
    (newParams: Record<string, string | null>, pathname?: string) => {
      const pathnameToUse = pathname ?? currentPathname;
      const params = new URLSearchParams(searchParams);

      for (const [name, value] of Object.entries(newParams)) {
        if (value === null) {
          params.delete(name);
        } else {
          params.set(name, value);
        }
      }

      return `${pathnameToUse}?${params.toString()}`;
    },
    [currentPathname, searchParams],
  );

  return createQueryString;
};
