import { User } from 'firebase/auth';
import { useIdTokenResult, useUser } from 'reactfire';

import { FullScreenSpinner } from '@/common/components/FullScreenSpinner';

/**
 * IMPORTANT: please ensure that the user is already defined before using this component
 */
export const FirebaseTokenLoader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const user = useUser().data as User; // user should already be defined if we are here
  const token = useIdTokenResult(user);

  if (!token) {
    return <FullScreenSpinner />;
  }

  return <>{children}</>;
};
